/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
import CloseIcon from '@material-ui/icons/Close'
import { StoryblokComponent } from '@storyblok/react'
import { useEffect } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { MenuFooter } from '@/app/common/components/Layout/MenuV2/MenuFooter'
import { useStoryblokConfig } from '@/app/common/context/storyblokConfigContext'
import { cn } from '@/utils/cn'

interface Props {
  open: boolean
  toggleOpen: () => void
}

export function Menu({ open, toggleOpen }: Props) {
  const {
    config: { upper_section_v2, lower_section_v2 },
  } = useStoryblokConfig()

  useEffect(() => {
    function handleEscapeKey(e: KeyboardEvent) {
      if (open && e.key === 'Escape') {
        toggleOpen()
      }
    }
    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [toggleOpen, open])

  if (!(upper_section_v2 && lower_section_v2)) {
    return null
  }

  return (
    <RemoveScroll enabled={open}>
      <div
        onClick={toggleOpen}
        className={cn('fixed inset-0 bg-black/75 z-[10000]', !open && 'hidden')}
      />
      <div
        className={cn(
          'fixed top-0 bottom-0 w-screen max-w-80 bg-white flex flex-col z-[10000] transition-[left] duration-300',
          open ? 'left-0' : '-left-80',
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-[var(--primary-header-height)] flex-shrink-0 flex flex-row justify-between items-center border-b border-[#E7E7E7]">
          <HeaderLogo />
          <button onClick={toggleOpen} className="px-4">
            <CloseIcon />
          </button>
        </div>

        <div className="h-screen overflow-auto">
          <div className="flex flex-col p-4 gap-8">
            {[...upper_section_v2, ...lower_section_v2].map((blok) => (
              <StoryblokComponent blok={blok} key={blok._uid} />
            ))}
          </div>
        </div>

        <div className="h-20 flex-shrink-0 flex flex-row justify-between p-3 gap-2 *:h-full *:flex-1">
          <MenuFooter toggleOpen={toggleOpen} />
        </div>
      </div>
    </RemoveScroll>
  )
}
