import ArrowSmall from '../assets/arrow_small.svg'
import * as Collapsible from '@radix-ui/react-collapsible'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { MenuItemGroupV2Storyblok } from '@/types/storyblok-component-types'

export default function MenuItemGroup({
  blok,
}: {
  blok: MenuItemGroupV2Storyblok
}) {
  const imageLoader = useStoryblokImageLoader({})
  return (
    <Collapsible.Root
      {...storyblokEditable(blok)}
      className="bg-[#F3F3F3] rounded-xl p-4"
    >
      <Collapsible.Trigger className="w-full flex flex-row gap-3 text-sm items-center font-medium group">
        {blok.icon?.filename && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            className="w-4 object-cover"
            src={imageLoader({
              src: blok.icon.filename,
              width: 20,
              quality: 90,
            })}
            alt=""
          />
        )}
        {blok.title}
        <ArrowSmall className="text-black ml-auto rotate-90 group-data-[state=open]:-rotate-90 transition-transform duration-100" />
      </Collapsible.Trigger>
      <Collapsible.Content>
        <div className="flex flex-col gap-2 p-2">
          {blok.menuItems.map((subItem) => {
            const link = getStoryBlokLink(subItem.link)
            return (
              <Link href={link} key={subItem.title} className="text-sm ml-12">
                {subItem.title}
              </Link>
            )
          })}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}
