import { storyblokEditable } from '@storyblok/react'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import {
  IconStoryblok,
  IconsStoryblok,
} from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'
import styles from './icons.module.css'

export default function Icons({ blok }: { blok: IconsStoryblok }) {
  const animationVars = {
    '--animation-duration': `${blok.AnimationDuration}s`,
  } as React.CSSProperties

  return (
    <div
      {...storyblokEditable(blok)}
      className="py-8 mx-auto w-full md:max-w-screen-md"
    >
      <div className="w-full text-sm text-center text-cashy-gray uppercase font-medium pb-3 leading-relaxed">
        {blok.Title}
      </div>
      <div className="relative w-min max-w-[100vw] overflow-x-hidden mx-auto [mask-image:_linear-gradient(to_right,transparent_0,_white_100px,_white_calc(100%-100px),transparent_100%)]">
        <div className={cn('w-max', styles.slideTrack)} style={animationVars}>
          {blok.Icons.map((icon) => (
            <OptionalLink blok={icon}>
              <IconImage blok={icon} />
            </OptionalLink>
          ))}
        </div>
        {/* 2nd slider creates the illusion of an infinite loop */}
        <div
          className={cn('absolute top-0 w-max', styles.secondSlideTrack)}
          style={animationVars}
        >
          {blok.Icons.map((icon) => (
            <OptionalLink blok={icon}>
              <IconImage blok={icon} />
            </OptionalLink>
          ))}
        </div>
      </div>
    </div>
  )
}

const OptionalLink: FunctionComponent<{
  blok: IconStoryblok
  children: ReactNode
}> = ({ blok, children }) => {
  if (blok.Link?.url) {
    return (
      <div className="inline">
        <a href={blok.Link.url}>{children}</a>
      </div>
    )
  }

  return children
}

// account for higher pixel densities
const iconWidths = [160, 260, 360, 640, 750, 828]

const IconImage: FunctionComponent<{
  blok: IconStoryblok
}> = ({ blok }) => {
  const loader = useStoryblokImageLoader({})
  const maxHeightInPx = 54
  const fallbackWidth = 300

  // determine the aspect ratio from the filename, so we can load the optimal width, instead of
  // a fixed huge width (i.e. 300px)
  const { width, originalWidth } = useMemo(() => {
    const regex = /\d+x\d+/
    const match = blok.Icon.filename.match(regex)

    if (!match || match.length !== 1) {
      console.warn(`Couldnt determine original size of icons blok`, {
        match,
        filename: blok.Icon.filename,
      })
      return { width: fallbackWidth, originalWidth: undefined }
    }
    const [originalWidth, originalHeight] = match[0].split('x')
    const ratio = Number(originalWidth) / Number(originalHeight)

    // prevent image upscaling
    const width = Math.min(
      Number((maxHeightInPx * ratio).toFixed(0)),
      Number(originalWidth),
    )
    return { width, originalWidth: Number(originalWidth) }
  }, [blok, maxHeightInPx])

  const iconSrcSet = useMemo(() => {
    const src = blok.Icon.filename

    return (
      iconWidths
        // prevent image upscaling
        .filter((iconWidth) => !originalWidth || iconWidth <= originalWidth)
        .map((width) => `${loader({ src, width, quality: 90 })} ${width}w`)
        .join(', ')
    )
  }, [loader, blok.Icon.filename, originalWidth])

  return (
    <picture key={blok.id} className="mx-6">
      <source srcSet={iconSrcSet} />
      <img
        src={loader({ src: blok.Icon.filename, width, quality: 90 })}
        alt={blok.Icon.alt}
        className="inline"
        style={{
          maxHeight: maxHeightInPx,
        }}
      />
    </picture>
  )
}
