import ArrowSmall from '../assets/arrow_small.svg'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { MenuItemV2Storyblok } from '@/types/storyblok-component-types'

export default function MenuItem({ blok }: { blok: MenuItemV2Storyblok }) {
  const imageLoader = useStoryblokImageLoader({})
  const link = getStoryBlokLink(blok.link)
  return (
    <Link
      {...storyblokEditable(blok)}
      href={link}
      className="bg-[#F3F3F3] rounded-xl flex flex-row p-4 gap-3 text-sm items-center font-medium"
    >
      {blok.icon?.filename && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="w-4 object-cover"
          src={imageLoader({
            src: blok.icon.filename,
            width: 20,
            quality: 90,
          })}
          alt=""
        />
      )}
      {blok.title}
      <ArrowSmall className="text-black ml-auto" />
    </Link>
  )
}
