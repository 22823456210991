import ArrowSmal from '../assets/arrow_small.svg'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import styled from 'styled-components'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import Text from '@/style/components/LegacyText'
import {
  MenuItemGroupStoryblok,
  MenuItemStoryblok,
} from '@/types/storyblok-component-types'

export type MenuItemProps = {
  isParent?: boolean
  openGroup?: (component: MenuItemGroupStoryblok) => void
  onClick?: (component: MenuItemStoryblok) => void
}

export default function MenuItemBlok({
  blok,
  isParent,
  openGroup,
  onClick,
}: { blok: MenuItemStoryblok } & MenuItemProps) {
  const props = {
    isParent: !!isParent,
    onClick: () => {
      if (isParent) {
        openGroup?.({
          ...blok,
          component: 'menu-item-group',
        })

        return
      }

      onClick?.(blok)
    },
  }

  const link = getStoryBlokLink(blok.link)
  const isLink = !!(link || blok.link?.id)
  const isExternalLink = blok.link?.linktype === 'asset'

  if (isLink) {
    return (
      <NavLink
        {...storyblokEditable(blok)}
        onClick={props.onClick}
        href={
          link.startsWith('/') || link.startsWith('https://')
            ? link
            : '/' + link
        }
        label={blok.label}
        isExternalLink={isExternalLink}
        showRightArrow={isParent}
      />
    )
  }

  return (
    <NavItem {...props} {...storyblokEditable(blok)}>
      {blok.label}
      {isParent && <ArrowSmal className="arrow text-white" />}
    </NavItem>
  )
}

type NavLinkProps = {
  label?: string
  onClick?: () => void
  href: string
  isExternalLink?: boolean
  showRightArrow?: boolean
}

const NavLink = ({
  isExternalLink,
  href,
  label = '',
  onClick,
  showRightArrow = false,
}: NavLinkProps) => {
  return (
    <NavItem
      onClick={onClick}
      href={href}
      as={Link}
      target={isExternalLink ? '_blank' : '_self'}
    >
      {label}
      {showRightArrow && <ArrowSmal className="arrow" />}
    </NavItem>
  )
}

const NavItem = styled(Text.ms)<MenuItemProps>`
  padding: 1.25rem 1.5rem;
  font-weight: ${(props) => (props.isParent ? '500' : '400')};
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    transition: background-color 0.05s;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transition: background-color 0.1s;
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg.inner-icon {
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.5rem;
  }

  svg.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;

    * {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`
