import { useStoryblokConfig } from '../../../context/storyblokConfigContext'
import LogoutIcon from '../assets/ico_logout.svg'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { StoryblokComponent } from '@storyblok/react'
import { isEmpty } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useCallback, useContext, useMemo, useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import Register from '@/app/auth/components/Register'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import { media } from '@/style/helpers'
import {
  MenuItemGroupStoryblok,
  MenuItemStoryblok,
} from '@/types/storyblok-component-types'
import MenuHeader, { ActionDiv } from './MenuHeader'

const sidebarWidthSmall = '17rem'
const sidebarWidth = '19rem'

interface Props {
  menuOpen: boolean
  toggleMenu: () => void
}

export default function Menu({ menuOpen, toggleMenu }: Props) {
  const [user] = useRecoilStateWithSSRFallback(customerState, undefined)
  const isLoggedIn = !!user
  const { t } = useTranslation()
  const modal = useContext(ModalDialogContext)
  const logoutCustomer = useLogoutCustomer()

  const {
    config: { upper_section, lower_section },
  } = useStoryblokConfig()

  const [navigationSectionHistory, setNavigationSectionHistory] = useState<
    string[]
  >([])

  const isRenderingRootNavigationSection = navigationSectionHistory.length === 0

  const currentUpperSectionItems = useMemo(() => {
    const parentCategoryId =
      navigationSectionHistory[navigationSectionHistory.length - 1]
    if (!parentCategoryId || !upper_section) {
      return upper_section || []
    }
    function findGroupByParentId(
      nodes: Array<MenuItemGroupStoryblok | MenuItemStoryblok>,
      parentId: string,
    ): Array<MenuItemGroupStoryblok | MenuItemStoryblok> | null {
      for (const node of nodes) {
        if (node._uid === parentId) {
          return node.items
        }
        if (node.items) {
          for (const item of node.items) {
            const found = findGroupByParentId(item.items || [], parentId)
            if (found) {
              return found
            }
          }
        }
      }
      return null
    }
    return findGroupByParentId(upper_section, parentCategoryId)
  }, [navigationSectionHistory, upper_section])

  const gotoPreviousGroup = () => {
    setNavigationSectionHistory((prev) => prev.slice(0, prev.length - 1))
  }

  const openGroup = (component: MenuItemGroupStoryblok | MenuItemStoryblok) => {
    if (component.component === 'menu-item') {
      return
    }

    if (component.items) {
      setNavigationSectionHistory((prev) => [...prev, component._uid!])
    }
  }

  const onClick = () => {
    toggleMenu?.()
  }

  const onClickLogin = () => {
    toggleMenu?.()
    modal.open(<Login />, { variant: 'full' })
  }

  const onClickRegister = () => {
    toggleMenu?.()
    modal.open(<Register />, { variant: 'full' })
  }

  const handleLogoutClick = useCallback(() => {
    toggleMenu?.()
    logoutCustomer()
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMenu, logoutCustomer])

  return (
    <RemoveScroll enabled={!!menuOpen}>
      <Backdrop menuOpen={!!menuOpen} onClick={toggleMenu} />

      <Container show={!!menuOpen} width={sidebarWidth}>
        {menuOpen && <CloseButton fontSize="large" onClick={toggleMenu} />}
        <HeaderWrapper>
          <MenuHeader
            showBackButton={!isRenderingRootNavigationSection}
            back={gotoPreviousGroup}
            user={user}
            onLogin={onClickLogin}
            onRegister={onClickRegister}
            toggleMenu={toggleMenu}
          />
        </HeaderWrapper>
        <NavLinksContainer>
          <NavLinkGroups>
            {currentUpperSectionItems?.map((link) => {
              return (
                <StoryblokComponent
                  key={link._uid}
                  blok={link}
                  openGroup={openGroup}
                  onClick={onClick}
                />
              )
            })}
          </NavLinkGroups>

          {isRenderingRootNavigationSection && (
            <NavLinkGroups showBorderTop={!isEmpty(lower_section)}>
              {lower_section?.map((link) => {
                return (
                  <StoryblokComponent
                    key={link._uid}
                    blok={link}
                    onClick={onClick}
                  />
                )
              })}
            </NavLinkGroups>
          )}
        </NavLinksContainer>
        {isLoggedIn && (
          <FooterWrapper>
            <NavFooter>
              <ActionDiv onClick={onClick}>
                <AccountSettingsLink href="/profile/edit">
                  {t('common:auth.account_settings')}
                </AccountSettingsLink>
              </ActionDiv>
              <SplitLine />

              <IconButton onClick={handleLogoutClick}>
                <LogoutIcon />
              </IconButton>
            </NavFooter>
          </FooterWrapper>
        )}
      </Container>
    </RemoveScroll>
  )
}

const Container = styled.div<{ show: boolean; width: string }>`
  position: relative;
  width: ${sidebarWidthSmall};
  height: 100%;
  background-color: #fff;
  z-index: 10000000;
  position: fixed;
  top: 0;
  transition: left 0.4s ease;
  left: ${({ show }) => (show ? '0' : `-${sidebarWidthSmall}`)};

  ${media.xs} {
    width: ${sidebarWidth};
    left: ${({ show }) => (show ? '0' : `-${sidebarWidth}`)};
  }
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #dfe1e5;
  padding: 0 1.5rem;
`

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 75px;
  border-top: 1px solid #dfe1e5;
  padding: 0 1.5rem;
  box-sizing: border-box;
`

const NavLinksContainer = styled.div`
  position: absolute;
  top: 75px;
  width: 100%;
  height: calc(100% - 75px - 75px);
  overflow-y: auto;
  padding-top: 1rem;
`

const NavLinkGroups = styled.div<{ showBorderTop?: boolean }>`
  border-top: ${(props) =>
    props.showBorderTop ? '1px solid #dfe1e5' : 'none'};
  ${media.lg} {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

const Backdrop = styled.div<{ menuOpen: boolean }>`
  display: ${(props) => (props.menuOpen ? 'block' : 'none')};
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 9999999;
  background-color: black;
  opacity: 0.75;
  height: 100vh;
  overflow: hidden;
`

const CloseButton = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  left: calc(${sidebarWidthSmall} + 10px);
  color: white;
  font-size: 2.5rem;

  ${media.xs} {
    left: calc(${sidebarWidth} + 10px);
  }
`

const NavFooter = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 75px;
  border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5rem;
  font-weight: 600;
  background: #ffffff;

  a {
    display: inline-block;
    text-decoration: none;
    color: unset;
  }
`

const AccountSettingsLink = styled(Link)`
  display: inline-block;
`

const SplitLine = styled.div`
  border-left: 2px solid #dfe1e5;
  height: 1.25rem;
  margin-left: auto;
  margin-right: 0.5rem;
`
