import { storyblokEditable } from '@storyblok/react'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import { FeaturedMenuItemStoryblok } from '@/types/storyblok-component-types'

export default function FeaturedMenuItem({
  blok,
}: {
  blok: FeaturedMenuItemStoryblok
}) {
  const imageLoader = useStoryblokImageLoader({})
  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-[#F3F3F3] rounded-xl flex flex-row"
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        className="object-cover w-24 rounded-l-xl"
        src={imageLoader({
          src: blok.image.filename,
          width: 100,
          quality: 100,
        })}
        srcSet={`${imageLoader({
          src: blok.image.filename,
          width: 100,
          quality: 100,
        })} 1x, ${imageLoader({
          src: blok.image.filename,
          width: 200,
          quality: 100,
        })} 2x`}
        alt=""
      />
      <div className="flex flex-col px-2 py-4">
        <span className="text-sm font-semibold">{blok.title}</span>
        <span className="text-xs text-[#272727]">{blok.caption}</span>
      </div>
    </div>
  )
}
