import { storyblokEditable } from '@storyblok/react'
import React, { useMemo } from 'react'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { HeroCardStoryblok } from '@/types/storyblok-component-types'

export default function HeroCard({ blok }: { blok: HeroCardStoryblok }) {
  const mobile_gradient_top =
    blok.mobile_gradient_color_top?.color || transparent
  const mobile_gradient_bottom =
    blok.mobile_gradient_color_bottom?.color || transparent

  const imageLoader = useStoryblokImageLoader({})

  const desktopSrcSet = useMemo(() => {
    const src = blok.hero_image_desktop?.filename

    return desktopScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 90 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image_desktop?.filename])

  const mobileSrcSet = useMemo(() => {
    const src = blok.hero_image_mobile?.filename

    return mobileScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 90 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image_mobile?.filename])

  return (
    <div className="flex justify-center p-4 lg:p-12 w-full">
      <div className={`lg:max-w-[1400px] w-full`} {...storyblokEditable(blok)}>
        {/** Desktop Version*/}
        <div
          className={`max-lg:hidden w-full`}
          style={{ height: `${blok.desktop_height}px` }}
        >
          <div
            className={`h-full flex flex-row justify-around items-center text-center gap-6`}
          >
            <RichTextParagraphStyles className="text-left w-[50%] break-words">
              <StoryblokRichText document={blok.desktop_content} />
            </RichTextParagraphStyles>

            <picture
              className="w-[50%] h-full"
              key={blok.hero_image_desktop.id}
            >
              <source media="(min-width: 1200px)" srcSet={desktopSrcSet} />
              <img
                className="rounded-xl object-cover object-center w-full h-full"
                src={imageLoader({
                  src: blok.hero_image_desktop?.filename,
                  width: 1200,
                })}
                alt={blok.hero_image_desktop.alt}
              />
            </picture>
          </div>
        </div>

        {/** Mobile Version*/}
        <picture
          className="relative flex lg:hidden w-full"
          key={blok.hero_image_mobile.id}
        >
          <source media="(max-width: 1199px)" srcSet={mobileSrcSet} />
          <img
            className="w-full h-full rounded-xl absolute left-0 top-0 object-center object-cover z-10"
            src={imageLoader({
              src: blok.hero_image_mobile?.filename,
              width: 750,
            })}
            alt={blok.hero_image_mobile.alt}
          />
          <div
            className="w-full h-full rounded-xl absolute top-0 z-20"
            style={{
              backgroundImage: `linear-gradient(to bottom, ${mobile_gradient_top} 20%, ${mobile_gradient_bottom})`,
            }}
          />
          <div
            className={`w-full h-full flex flex-col ${mobile_alignment[blok.mobile_content_alignment]} items-center text-center px-6 py-16 z-30`}
            style={{
              height: `${blok.mobile_height}px`,
            }}
          >
            <RichTextParagraphStyles className="break-words">
              <StoryblokRichText document={blok.mobile_content} />
            </RichTextParagraphStyles>
          </div>
        </picture>
      </div>
    </div>
  )
}

const desktopScreenWidths = [1200, 1920]
const mobileScreenWidths = [360, 640, 750, 828, 1080, 1200]

const mobile_alignment: { [key: string]: string } = {
  top: 'justify-start',
  center: 'justify-center',
  bottom: 'justify-end',
}

const transparent = `rgba(0, 0, 0, 0)`
